export class User {
  bio?: string;
  catchAndRelease?: boolean;
  city?: string;
  cityCoordinates?: [number, number];
  connections?: string[];
  connectionRequests?: ConnectionRequest[];
  connectionRequestsSent?: ConnectionRequest[];
  cover_photo?: string;
  created_at?: string;
  daysPerYear?: string;
  email?: string;
  experience?: string;
  favoriteMarkers?: FavoriteMarker[];
  fishTypes?: number[];
  isNewUser?: boolean;
  isPublicProfile?: boolean;
  isVanishMode?: boolean;
  knownAreas?: string[];
  name?: string;
  phone?: string;
  profile_picture?: string;
  region?: string;
  savedGuages?: any[];
  savedStreams?: any[];
  subscriptionInfo?: SubscriptionInfo;
  techniqueStyle?: number[];
  uid?: string;
  username?: string;
  waterTypes?: number[];
  handle?: string;
  isPremium?: boolean;
  
  constructor(data: Partial<User> = {}) {
    this.bio = data.bio || "";
    this.catchAndRelease = data.catchAndRelease || false;
    this.city = data.city || "";
    this.cityCoordinates = data.cityCoordinates || [0, 0];
    this.connections = data.connections || [];
    this.connectionRequests =
      data.connectionRequests?.map((cr) => new ConnectionRequest(cr)) || [];
    this.connectionRequestsSent =
      data.connectionRequestsSent?.map((crs) => new ConnectionRequest(crs)) ||
      [];
    this.cover_photo = data.cover_photo || "";
    this.created_at = data.created_at || "";
    this.daysPerYear = data.daysPerYear || "";
    this.email = data.email || "";
    this.experience = data.experience || "";
    this.favoriteMarkers = data.favoriteMarkers || [];
    this.fishTypes = data.fishTypes || [];
    this.isNewUser = data.isNewUser || false;
    this.isPublicProfile = data.isPublicProfile || false;
    this.isVanishMode = data.isVanishMode || false;
    this.knownAreas = data.knownAreas || [];
    this.name = data.name || "";
    this.phone = data.phone || "";
    this.profile_picture = data.profile_picture || "";
    this.region = data.region || "";
    this.savedGuages = data.savedGuages || [];
    this.savedStreams = data.savedStreams || [];
    this.subscriptionInfo = data?.subscriptionInfo ? new SubscriptionInfo(data.subscriptionInfo) : new SubscriptionInfo();
    this.techniqueStyle = data.techniqueStyle || [];
    this.uid = data.uid || "";
    this.username = data.username || "";
    this.waterTypes = data.waterTypes || [];
    this.handle = data.handle || "";
    this.isPremium = data.subscriptionInfo?.paymentStatus === "Premium" || false;
  }
}

export class ConnectionRequest {
  user: string;
  date: string;

  constructor(data: Partial<ConnectionRequest> = {}) {
    this.user = data.user || "";
    this.date = data.date || "";
  }
}

export class UserConnection {
  user: string;
  date: string;

  constructor(data: Partial<UserConnection> = {}) {
    this.user = data.user || "";
    this.date = data.date || "";
  }
}

// Move to Maps Classing
export class FavoriteMarker {
  coordinates?: string[];
  markerName?: string;
  markerType?: string;
  markerColor?: string;
  imageUrls?: string[];
  notes?: string;
  publicView?: boolean;
  uid?: string;

  constructor(data: Partial<FavoriteMarker> = {}) {
    this.coordinates = data?.coordinates || [];
    this.markerName = data?.markerName || "";
    this.markerType = data?.markerType || "Information";
    this.markerColor = data?.markerColor || "#ff0000";
    this.imageUrls = data?.imageUrls || [];
    this.notes = data?.notes || "";
    this.publicView = data?.publicView || false;
    this.uid = data?.uid || "";
  }
}

// Move to Maps Classing
export class Stream {
  streamName?: string;
  streamType?: "River" | "Creek" | "Other";
  constructor(data: Partial<Stream> = {}) {
    this.streamName = data.streamName;
    this.streamType = data.streamType;
  }
}

export class SubscriptionInfo {
  paymentStatus?: "Premium" | "Free";
  validityDate?: string; // Current Valid Until Date
  hadFreeTrial?: boolean;
  currentProvider?: "apple" | "google" | "stripe" | "gift" | "other";
  currentSubscriptionId?: string;
  purchaseHistory?: PurchaseHistory[];
  stripeCustomerId?: string;
  subscriptionStates?: any[];
  subscriptionStatus?: string;
  appleCustomerId?: string;
  googleCustomerId?: string;
  subscriptionEvents?: any[];
  invoiceHistory?: InvoiceItem[];

  constructor(data: Partial<SubscriptionInfo> = {}) {
    this.paymentStatus = data?.paymentStatus || "Free";
    this.validityDate = data?.validityDate;
    this.purchaseHistory = data?.purchaseHistory?.map(
      (ph) => new PurchaseHistory(ph)
    );
    this.hadFreeTrial = data?.hadFreeTrial || false;
    this.currentProvider = data?.currentProvider;
    this.currentSubscriptionId = data?.currentSubscriptionId;
    this.stripeCustomerId = data?.stripeCustomerId;
    this.appleCustomerId = data?.appleCustomerId;
    this.googleCustomerId = data?.googleCustomerId;
    this.subscriptionStates = data?.subscriptionStates || [];
    this.subscriptionStatus = data?.subscriptionStatus;
    this.subscriptionEvents = data?.subscriptionEvents || [];
    this.invoiceHistory = data?.invoiceHistory?.map(
      (ih) => new InvoiceItem(ih)
    );
  }
}

export class PurchaseHistory {
  dateOfPurchase?: string;
  pointOfPurchase?: "apple" | "google" | "stripe";
  sku?: string;
  transactionID?: string;
  validUntil?: string;

  constructor(data: Partial<PurchaseHistory> = {}) {
    this.dateOfPurchase = data?.dateOfPurchase;
    this.sku = data?.sku;
    this.pointOfPurchase = data?.pointOfPurchase;
    this.transactionID = data?.transactionID;
    this.validUntil = data?.validUntil;
  }
}

export class InvoiceItem {
  invoiceURL?: string;
  invoiceDate?: string;
  invoiceAmount?: number;
  invoiceStatus?: string;

  constructor (data: Partial<InvoiceItem> = {}) {
    this.invoiceURL = data?.invoiceURL;
    this.invoiceDate = data?.invoiceDate;
    this.invoiceAmount = data?.invoiceAmount;
    this.invoiceStatus = data?.invoiceStatus;
  }
}

export class ShareTo {
  // Main Id
  shareId?: string;
  // Uid of User - Set after acct creation
  recipient?: string;
  // Uid of referrer
  referrer?: string;
  // Email shared
  shareEmail?: string;
  // Later could figure out text share
  sharePhone?: string;
  // All other stuff, dates, etc.
  // ... Other stuff
}

// Move to Maps Classing
export type MarkerType =
  | "Access Point"
  | "Bicycle"
  | "Boat Ramp"
  | "Campsite"
  | "Dam"
  | "Fuel"
  | "Information"
  | "Marker"
  | "Mountain"
  | "Parking"
  | "Restaurant"
  | "Hazard"
  | "Snowmobile"
  | "Stream Gage"
  | "Restroom"
  | "Trailhead"
  | "Waterfall"
  | "Business";
