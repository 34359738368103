import { UserState } from "../appState";
import { User } from "../models/User";

const fffAPIURL = `${process.env.REACT_APP_API_URL}/api`;

class FFFAPI {
    public static async setUserAffiliate(affiliateObj: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            fetch(`${fffAPIURL}/users/setUserAffiliate`, {
                method: "POST",
                headers: getAPIHeaders(),
                body: JSON.stringify({
                    "affiliate": affiliateObj,
                }),
            }).then((res) => {
                resolve(res.json());
            }).catch((err) => {
                console.log(err);
            });
        });
    }

    public static async getCheckoutSession(sessionID: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            fetch(`${fffAPIURL}/orders/getAvantOrderDetails`, {
                method: "POST",
                headers: getAPIHeaders(),
                body: JSON.stringify({
                    "sessionId": sessionID,
                }),
            }).then((res) => {
                resolve(res.json());
            }).catch((err) => {
                console.log(err);
            });
        });
    }

    public static async createUser(user: User): Promise<any> {
        return new Promise(async (resolve, reject) => {
            fetch(`${fffAPIURL}/user/createUser`, {
                method: "POST",
                headers: getAPIHeaders(),
                body: JSON.stringify(user),
            }).then((res) => {
                resolve(res.json());
            }).catch((err) => {
                console.log(err);
            });
        });
    }

    public static async updateUser(user: User): Promise<any> {
        return new Promise(async (resolve, reject) => {
            fetch(`${fffAPIURL}/user/updateUser`, {
                method: "POST",
                headers: getAPIHeaders(),
                body: JSON.stringify(user),
            }).then((res) => {
                resolve(res.json());
            }).catch((err) => {
                console.log(err);
            });
        });
    }

    public static async cancelSubscription(subscriptionID: string, cancelDetails: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            fetch(`${fffAPIURL}/orders/cancelUserStripeSubscription`, {
                method: "POST",
                headers: getAPIHeaders(),
                body: JSON.stringify({
                    "subscriptionID": subscriptionID,
                    "cancellationDetails": cancelDetails,
                }),
            }).then((res) => {
                resolve(res.json());
            }).catch((err) => {
                console.log(err);
            });
        });
    }
}

export default FFFAPI;

const getAPIHeaders = () => {
    const UserToken = localStorage.getItem("ffftoken");
    const UID = UserState.getState().uid || "";
    return new Headers({
        "content-type": "application/json",
        "authorization": `${UserToken}`,
        "x-user-id": UID,
        
    });
};