import { useEffect, useState } from "react";
import "../../styles/login.scss";
import "../../styles/redeem-code.scss";
import phoneDisplays from "../../assets/img/phone_displays.png";
import {ReactComponent as PremiumIcon} from "../../assets/img/premium_icon.svg";
import getOnGoogle from "../../assets/img/getOnGoogle.png";
import getOnApple from "../../assets/img/getOnApple.png";

const RedeemConfirm = () => {
  const [dCode, setDCode] = useState<string>("");

  useEffect(() => {
    // Capture Affiliate ID from URL
    const urlParams = new URLSearchParams(window.location.search);
    setDCode(urlParams?.get('discountcode') || "");
  }, []);

  return (
    <div id="redeem-confirm" className="redeem-confirm">
      <div className="redeem-confirm-content">
        <div className="redeem-steps">
        <div className="step">
          <p className="step-num complete">1</p>
          <h3 className="brand-color-1">Sign Up</h3>
        </div>
        <div className="step-div complete"></div>
        <div className="step">
          <p className="step-num complete">2</p>
          <h3 className="brand-color-1">{!!dCode ? `Claim Discount` : `Start Trial`}</h3>
        </div>
        <div className="step-div complete"></div>
        <div className="step">
          <p className="step-num current">3</p>
          <h3>Get App</h3>
        </div>
        </div>
        <div className="confirm-content">
        <div className="content center-content">
          <img id="phone-images" src={phoneDisplays}></img>
        </div>
        <div className="content">
          <div className="redeem-confirm-text">
            <h2 className="brand-color-1">{!!dCode ? `Your discount has been applied. ` : ``}Download the app to get started!</h2>
            <div className="detail-step">
              <div className="step-extra">
                <p className="step-num"><PremiumIcon/></p>
              </div>
              <div className="step-text">
                <p>Enjoy your <b>FREE</b> 14 day premium trial plan. After the trial ends, you will be billed the {!!dCode ? `discounted amount` : `standard rate`} and will have Premium access for a year.</p>
              </div>
            </div>
          </div>
          <div className="app-download">
            <a href="https://apps.apple.com/us/app/flyfishfinder-fly-fishing-hub/id6446231902" target="_blank" rel="noreferrer">
              <img height="60" src={getOnApple} alt="Get it on Google Play" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.flyfishfinderappv2" target="_blank" rel="noreferrer">
              <img height="60" src={getOnGoogle} alt="Get it on Google Play" />
            </a>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemConfirm;
