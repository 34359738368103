import { useContext, useEffect, useState } from "react";
import "../../styles/login.scss";
import "../../styles/redeem-code.scss";
import { UserState } from "../../appState";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Check, Water } from "@mui/icons-material";
import logo from "../../assets/img/FFF_Logo_Orange_Horizontal.png";
import cta1 from "../../assets/img/topobg.png";
import { ElfsightWidget } from 'react-elfsight-widget';
import {ReactComponent as DownloadIcon} from "../../assets/img/download_icon.svg";
import {ReactComponent as PremiumIcon} from "../../assets/img/premium_icon.svg";
import {ReactComponent as LockIcon} from "../../assets/img/lock_icon.svg";
import {ReactComponent as GroupIcon} from "../../assets/img/group_icon.svg";
import {ReactComponent as MapIcon} from "../../assets/img/map_icon.svg";
import {ReactComponent as TourGuideIcon} from "../../assets/img/tourguide_icon.svg";
import ffipLogo from "../../assets/img/partner_img/ffip_logo.png";
import flysmithLogo from "../../assets/img/partner_img/flysmith_logo.png";
import lidrigLogo from "../../assets/img/partner_img/lidrig_logo.png";
import mainleyLogo from "../../assets/img/partner_img/mainley_logo.png";
import riverratLogo from "../../assets/img/partner_img/riverrat_logo.png";
import phoneDisplays from "../../assets/img/phone_displays.png";
import { Context } from "../../context/AuthContext";
import ConservationImg from "../../assets/img/conservation-1.png";

const orderApiUrl = `${process.env.REACT_APP_API_URL}/api/orders`;

const RedeemPayment = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState<string>("Pro Nationwide");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const user = useContext(Context);

  const [dCode, setDCode] = useState<string>("");


  useEffect(() => {
    // Capture Affiliate ID from URL
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateId = urlParams.get('avad');
    setDCode(urlParams?.get('discountcode') || "");
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    console.log(affiliateId);
    console.log(futureDate);
    if (dCode) {
      document.getElementById('promo-code-input')?.setAttribute('value', dCode);
    }

  }, []);

  const redeemPromoCode = async() => {
    setErrorMsg("");
    
    const checkoutSession: any = await getCheckoutSession();
    if (!!checkoutSession.error) {
      return;
    } else if (!!checkoutSession.data) {
      const checkoutUrl = checkoutSession?.data;
      window.location.href = checkoutUrl; 
      return;
    }
  };

  const getCheckoutSession = async () => {
    let priceId = "";
    if (product === "Pro 2 States") {
      priceId = `${process.env.REACT_APP_2_STATE_PRICEID}`;
    } else if (product === "Pro Nationwide") {
      priceId = `${process.env.REACT_APP_NATIONWIDE_PRICEID}`;
    } else if (product === "Elite") {
      priceId = `${process.env.REACT_APP_ELITE_PRICEID}`;
    }

    let reqObj: any = {
      "priceId": priceId,
      "user": UserState?.getState()?.uid,
      "email": UserState?.getState()?.email,
    }
    if (dCode) {
      reqObj["promoCode"] = dCode;
    }
    return new Promise(async (resolve, reject) => {
      fetch(`${orderApiUrl}/createCheckoutSession`, {
        method: "POST",
        headers: getOrdersHeaders(),
        body: JSON.stringify(reqObj),
      }).then((res) => {
        if (res.status === 200) {
          return resolve(res.json());
        } else if (res.status === 500) {
          setErrorMsg("Invalid Promo Code Applied");
        }
      }).catch((err) => {
        setErrorMsg(err.message);
        console.log(err);
      });
    });
  }

  function getOrdersHeaders() {
    const UserToken = localStorage.getItem("ffftoken");
    const UID = UserState.getState().uid || "";
    return new Headers({
      "content-type": "application/json",
      "authorization": `${UserToken}`,
      "x-user-id": UID,
    });
  }

    const handleSignOut = async () => {
      const auth = getAuth();
      try {
        await signOut(auth);
        navigate("/");
      } catch (e) {
        console.error(e);
      }
    };

  return (
    <div id="redeem-payment">
      <div className="redeem-payment-content">
        <div className="logo">
          <img src={logo} alt="Fly Fish Finder Logo" />
        </div>
        <div className="redeem-steps">
        <div className="step">
          <p className="step-num complete">1</p>
          <h3 className="brand-color-1-light">Sign Up</h3>
        </div>
        <div className="step-div complete"></div>
        <div className="step">
          <p className="step-num current">2</p>
          <h3 className="brand-color-1">{!!dCode ? `Claim Discount` : `Start Trial`}</h3>
        </div>
        <div className="step-div"></div>
        <div className="step">
          <p className="step-num">3</p>
          <h3 className="grey-color">Get App</h3>
        </div>
        </div>
        <div id="discount-redeem-form">
        <div id="discount-redeem-text">
          <div className="inner-wrap">
            <h2 className="header">{!!dCode ? `Claim your Affiliate Discount now` : `Lock in your Free Trial today`}</h2>
            {/* <p className="sub-header">Choose your preferred premium plan,{!!dCode ? ` enter your discount code,` : ``} checkout and instantly access 225k+ Rivers and Streams. Enjoy your <b>14 day FREE trial</b>, cancel your trial anytime and you won't be charged.</p> */}
            <p className="sub-header">Checkout and instantly access 225k+ Rivers and Streams. Enjoy your <b>14 day FREE trial</b>, cancel your trial anytime and you won't be charged.</p>
            <div className="detail-step">
            <div className="step-extra">
              <p className="step-num"><LockIcon/></p>
            </div>
            <div className="step-text">
              <p>Lock in your {!!dCode ? `discount` : `trial`}, you will not be charged while picking a plan</p>
            </div>
            </div>
            <div className="detail-step">
            <div className="step-extra">
              <p className="step-num"><DownloadIcon/></p>
            </div>
            <div className="step-text">
              <p>Download the app and login with the account you have created</p>
            </div>
            </div>
            <div className="detail-step">
              <div className="step-extra">
                <p className="step-num"><PremiumIcon/></p>
              </div>
              <div className="step-text">
                <p>Enjoy your <b>{!!dCode ? `Discounted Purchase and a ` : ``}14 Day FREE Trial</b>. After the trial ends, you will be billed the {!!dCode ? `discounted amount` : `standard rate`} and will have Premium access for a year.</p>
            </div>
            </div>
          </div>
        </div>
        <div id="discount-redeem-input">
          {/* <h2 className="cta-heading">Choose your trial plan</h2> */}
          <div className="code-redeem">
            {/* <div className="input-section">
              <input id="promo-code-input" name="promo-code" type="text" placeholder="Discount Code" />
              { !!errorMsg && <p className="error">{errorMsg}</p> }
            </div> */}
            <button className="success fill" onClick={() => {
              redeemPromoCode();
            }}>{!!dCode ? "Claim Deal!" : "Start Now" }</button>
          </div>
          { !!errorMsg && <p className="error">{errorMsg}</p> }
          <div className="product-select">
            {/* <div className={`product-option ${product === "Pro 2 States" && "active"}`} onClick={() => {
              setProduct("Pro 2 States");
            }} >
              <div className="product-option-text">
                <div className="product-name">
                  <p className="concat"><span className="price"><s>$29.99</s></span><span className="price-suffix"><s>per year</s></span> <span className="price-cta">FREE</span> <span className="price-cta-suffix">14 day Trial</span></p>
                  <h3>Pro 2 State</h3>
                </div>
                <div className="product-features">
                  <ul>
                    <li>Access Maps across 2 States</li>
                    <li>Get involved with the Community</li>
                  </ul>
                </div>
              </div>
              <div className="active-marker">
                <div className="check-contain">
                  <Check/>
                </div>
              </div>
            </div> */}
            <div 
            className={`product-option ${product === "Pro Nationwide" && "active"}`}
            onClick={() => {
              setProduct("Pro Nationwide");
            }}>
                <div className="product-option-text">
                <div className="product-name">
                  <p>Lock in your {!!dCode ? `discount and ` : ``}14 day FREE trial</p>
                  {/* <p className="concat"><span className="price"><s>$29.99</s></span><span className="price-suffix"><s>per year</s></span> <span className="price-cta">FREE</span> <span className="price-cta-suffix">14 day Trial</span></p> */}
                  <h3>Pro Nationwide</h3>
                </div>
                  <div className="product-features">
                    <ul>
                      <li>Access Maps Nationwide</li>
                      <li>Get involved with the Community</li>
                      <li>Connect with Guides</li>
                    </ul>
                  </div>
                </div>
                <div className="active-marker">
                  <div className="check-contain">
                    <Check/>
                  </div>
                </div>
            </div>
          </div>
          <div className="proceed-cta">
            <img src={ConservationImg} height={32} width={32}></img>
            <p className="txt-14">A portion of proceeds go to fish conservation</p>
          </div>
        </div>
        <div id="reviews-section">
          <ElfsightWidget widgetId="ce2c181a-0a7f-4e4c-acc9-52153afb72b9"/>
        </div>
        </div>
        <div className="banner-cta reverse" style={{
          background: `url(${cta1}) no-repeat center center, #f3f4f4`
          }
          }>
          <div className="banner-side-1">
            <h3 className="header">
              Connect With Community.<br></br>
              <span>Find New Fishing Buddies.</span>
            </h3>
            <p className="sub-text">
            From scouting water with the most detailed fly fishing maps on the market, to building your fishing network so you can take more trips, our tools will help get you there.            </p>
          </div>
          <div className="banner-side-2">
            <div className="img-stack">
              <img id="phone1-bg" src={phoneDisplays}></img>
            </div>
          </div>
        </div>
        <div id="discount-redeem-feature-boxes">
        <div className="feature">
          <div className="svg-contain">
            <MapIcon/>
          </div>
          <h4>Maps & Filters</h4>
          <p>As an angler you're not limited to one fish, so you're app shouldn't be either. Find the best rivers and lakes with ease with 225k+ Rivers and Streams mapped for over 100 fish species. Over a million additional points of interest have been mapped including stream gauges, fly shops, bridge access and more.</p>
        </div>
        <div className="feature">
          <div className="svg-contain">
            <GroupIcon/>
          </div>
          <h4>Get involved with the Community</h4>
          <p>Join the first app of it's kind built for fly fishing community. Discover anglers across the country, plan trips and catch more fish all for less!</p>
        </div>
        <div className="feature">
          <div className="svg-contain">
            <TourGuideIcon/>
          </div>
          <h4>Connect with Guides</h4>
          <p>Completely DIY apps are bad for the backbone of the fishing industry, America's fly fishing Guides & Shops. That's why we're building game changing features that make it possible (and affordable) to take more trips and explore a ton of new water faster than ever before.</p>
        </div>
        </div>
        <div className="banner-cta" style={{
          background: `url(${cta1}) no-repeat center center, #f3f4f4`
          }
          }>
          <div className="banner-side-1">
          <h3 className="header">Built for Adventure.</h3>
          <p className="sub-text">From scouting water with the most detailed fly fishing maps on the market, to building your fishing network so you can take more trips, our tools will help get you there.</p>

          </div>
          <div className="banner-side-2">
            <iframe id="fff-video" src="https://www.youtube.com/embed/jZAt9s-51aY?si=j-2eTgfaEAvxzjKY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>
        </div>
        <div id="partner-section">
          <p>Proud to be Partners with</p>
          <div className="image-section">
            <img src={mainleyLogo}></img>
            <img src={riverratLogo}></img>
            <img src={flysmithLogo}></img>
            <img src={ffipLogo}></img>
            <img src={lidrigLogo}></img>
          </div>
        </div>
        <div id="payment-logout">
          <button className="primary outline" onClick={() => {
            handleSignOut()
          }}>Log Out</button>
        </div>
      </div>
    </div>
  );
};

export default RedeemPayment;

const getCookie = (key: any) => {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}