import { useEffect, useState } from "react";
import { UserState } from "../appState";
import ProtectedRoute from "../components/guards/protected-route";
import FFFAPI from "../services/fffAPI.service";
import "../styles/cancel-subscription.scss";
import logo from "../assets/img/FFF_Logo_Orange_Horizontal.png";

const CancelSubscription = () => {
    // User State
    const storeUser = UserState((state) => state);
    const [reason, setReason] = useState<string>("");
    const [comment, setComment] = useState<string>("");

    const [hasBeenCanceled, setHasBeenCanceled] = useState<boolean>(false);
    const [errorCanceling, setErrorCanceling] = useState<boolean>(false);

    let currentProvider;
    switch (storeUser?.subscriptionInfo?.currentProvider) {
        case "stripe":
            currentProvider = "Stripe";
            break;
        case "apple":
            currentProvider = "Apple";
            break;
        case "google":
            currentProvider = "Google";
            break;
        default:
            currentProvider = null;
            break;
    }

    const currentStatus = storeUser?.subscriptionInfo?.subscriptionStatus;
    
    useEffect(() => {
        console.log("User", storeUser);
    }  , [storeUser]);

    const cancelStripeSubscription = async () => {
        // Call API to cancel subscription
        let cancelDetails = {
            feedback: "",
            comment: ""
        }
        if (reason) {
            cancelDetails.feedback = reason;
        }
        if (comment) {
            cancelDetails.comment = comment;
        }
        if (storeUser?.subscriptionInfo?.currentSubscriptionId) {
            FFFAPI.cancelSubscription(storeUser?.subscriptionInfo?.currentSubscriptionId, cancelDetails).then((res: any) => {
                // SUBSCRIPTION HAS BEEN CANCELED
                setHasBeenCanceled(true);
                setErrorCanceling(false);
                // TODO - Need to also set the local user object to reflect the change immediately
                console.log(res);
            }).catch((err) => {
                // ERROR CANCELING SUBSCRIPTION
                console.log(err);
                setHasBeenCanceled(false);
                setErrorCanceling(true);
            });
        } else {
            // NO SUBSCRIPTION ID FOUND
            console.log("No Subscription ID Found");
        }
    }
  return (
    <ProtectedRoute>
        <div className="cancel-subscription-page">
        <div className="logo">
          <img src={logo} alt="Fly Fish Finder Logo" />
        </div>
            {storeUser?.subscriptionInfo?.paymentStatus === "Free" || !currentProvider || (currentStatus !== "current" && currentStatus !== "trial") ? (
                <div className="no-subscription">
                    <div className="heading-section">
                        <h1 className="main">No Subscription Found</h1>
                        <p className="sub">A current premium subscription for your user was not found. If you feel there may be an issue please contact our support team.</p>
                    </div>
                </div>
            ) :
            storeUser?.subscriptionInfo?.paymentStatus === "Premium" &&
            currentProvider === "Stripe" &&
            (currentStatus === "current" || currentStatus === "trial") ?
            !hasBeenCanceled ?
            (<div className="cancel-subscription">
                <div className="heading-section">
                    <h3 className="main">Cancel Your Subscription</h3>
                    <p className="sub">We are sad to see you go. We would love to hear your feedback on how we can do better in the future.</p>
                </div>
                <div className="content-section">
                    <div className="cancel-form">
                    <div className="input-group">
                <label htmlFor="customer-reason">Reason for Cancellation</label>
                <fieldset id="customer-feedback">
                    {[
                        { value: "customer_service", label: "Customer Service" },
                        { value: "low_quality", label: "Low Quality" },
                        { value: "missing_features", label: "Missing Features" },
                        { value: "too_complex", label: "Too Complex" },
                        { value: "too_expensive", label: "Too Expensive" },
                        { value: "unused", label: "Unused" },
                        { value: "other", label: "Other" }
                    ].map((option) => (
                        <div className="radio-container" key={option.value}>
                            <input
                                type="radio"
                                value={option.value}
                                name="customer-reason"
                                checked={reason === option.value}
                                onChange={(e) => setReason(e.target.value)}
                            />
                            <label>{option.label}</label>
                        </div>
                    ))}
                </fieldset>
            </div>
            <div className="input-group">
                <label htmlFor="customer-comment">Customer Comment</label>
                <textarea
                    id="customer-comment"
                    placeholder="Write your comments..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>
            <button className="primary outline" onClick={cancelStripeSubscription}>
                Cancel My Subscription
            </button>
                    </div>
                </div>
            </div>)
            :
            (
                <div className="cancel-subscription">
                    <div className="heading-section">
                        <h3 className="main">Your subscription has been successfully canceled.</h3>
                        <p className="sub">We are sad to see you go. We would love to hear your feedback on how we can do better in the future.</p>
                    </div>
                </div>
            )
            : 
            (<div>
                <div className="other-provider">
                    <div className="heading-section">
                        <h3 className="main">Your subscription needs to be canceled through your current provider.</h3>
                        <p className="sub">Provider: {storeUser?.subscriptionInfo?.currentProvider}</p>
                    </div>
                </div>
            </div>)}
        </div>
    </ProtectedRoute>
  );
}

export default CancelSubscription;