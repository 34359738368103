import { create } from "zustand";
import { layers } from "./data/maps/mapLayers";
import { FavoriteMarker, Stream, SubscriptionInfo, User } from "./models/User";
import { createRef } from "react";

let mapRef = createRef<any>() as React.MutableRefObject<any>;
mapRef.current = false;

interface DetailPaneData {
  type: string;
  config: {
    paneTitle: string;
    closeCallback: () => void;
  };
  data: {
    [key: string]: any;
  };
}

interface MapState {
  map: any;
  allowRotate: boolean;
  layersOpen: boolean;
  streamLayersOpen: boolean;
  currentMapStyle: string;
  availableMapFilters: any[];
  markerCaptureEnabled: boolean;
  detailPaneExpanded: boolean;
  detailPaneData: DetailPaneData | null; //any | null;
  currentMarker: any | null;
  terrainModeEnabled: boolean;
  setMap: (map: any) => void;
  setLayersOpen: (open: boolean) => void;
  setStreamLayersOpen: (open: boolean) => void;
  closeAllControls: () => void;
  setAllowRotate: (allow: boolean) => void;
  toggleMapFilter: (filter: string, value: any) => void;
  setMarkerCapture: (enabled: boolean) => void;
  setDetailPaneExpanded: (enabled: boolean) => void;
  updateDetailPane: (updates: any) => void;
  setCurrentMarker: (marker: any) => void;
  clearMapContext: () => void;
  toggleTerrainMode: (enabled: boolean) => void;
  setMapStyle: (style: string) => void;
}

export const MapState = create<MapState>((set) => ({
  map: mapRef,
  allowRotate: false,
  layersOpen: false,
  streamLayersOpen: false,
  currentMapStyle: "basic",
  availableMapFilters: layers,
  markerCaptureEnabled: false,
  detailPaneExpanded: false,
  detailPaneData: null,
  currentMarker: null,
  terrainModeEnabled: false,
  setMap: (map: any) => set((state) => ({ map: map })),
  setAllowRotate: (allow: boolean) => set((state) => ({ allowRotate: allow })),
  setLayersOpen: (open: boolean) => set((state) => ({ layersOpen: open })),
  setStreamLayersOpen: (open: boolean) =>
    set((state) => ({ streamLayersOpen: open })),
  closeAllControls: () =>
    set((state) => ({ layersOpen: false, streamLayersOpen: false })),
  setMapStyle: (style: string) => set((state) => ({ currentMapStyle: style })),
  toggleMapFilter: (id, value) =>
    set((state) => ({
      availableMapFilters: state.availableMapFilters.map((filter) => {
        return filter.layerId === id ? { ...filter, visible: value } : filter;
      }),
    })),
  setMarkerCapture: (enabled: boolean) =>
    set((state) => ({ markerCaptureEnabled: enabled })),
  setDetailPaneExpanded: (expanded: boolean) =>
    set((state) => {
      // Set the detailPaneExpanded state
      const newState = { detailPaneExpanded: expanded };
      // Check if expanded is false and update detailPaneData accordingly
      if (!expanded) {
        set((state) => ({ detailPaneData: null }));
        if (!!state.map?.current?._popups.length) {
          state.map?.current?._popups.forEach((popup: any) => {
            popup.remove();
          });
        }
      }
      return newState;
    }),
  updateDetailPane: (updates: any | null) =>
    set((state) => ({ detailPaneData: updates })),
  setCurrentMarker: (marker: any | null) =>
    set((state) => ({ currentMarker: marker })),

  clearMapContext: () => {
    set((state) => ({
      allowRotate: false,
      layersOpen: false,
      streamLayersOpen: false,
      availableMapFilters: layers,
      markerCaptureEnabled: false,
      detailPaneExpanded: false,
      detailPaneData: null,
      currentMarker: null,
    }));
  },
  toggleTerrainMode: (enabled: boolean) => {
    set((state) => ({ terrainModeEnabled: enabled }));
  },
}));

// TODO - Change out this for the User model, migrate that to interface
interface UserState {
  authUser: any;
  uid?: string;
  name?: string;
  email?: string;
  cover_photo?: string;
  favoriteMarkers?: FavoriteMarker[];
  savedStreams?: Stream[];
  subscriptionInfo?: SubscriptionInfo;
  userPreferences?: any;
  setAuthUser: (user: any) => void;
  setUser: (user: User) => void;
  setFavoriteMarkers: (markers: FavoriteMarker[]) => void;
  setRecentColors: (colors: string[]) => void;
}

export const UserState = create<UserState>((set) => ({
  authUser: null,
  uid: "",
  name: "",
  email: "",
  cover_photo: "",
  favoriteMarkers: [],
  savedStreams: [],
  subscriptionInfo: new SubscriptionInfo(),
  userPreferences: {},
  setAuthUser: (user: any) => set((state) => ({ authUser: user })),
  setUser: (user: User) =>
    set((state) => {
      const newState = {
        uid: user.uid,
        name: user.name,
        email: user.email,
        cover_photo: user.cover_photo,
        favoriteMarkers: user.favoriteMarkers,
        savedStreams: user.savedStreams,
        subscriptionInfo: user.subscriptionInfo,
      };
      return newState;
    }),
  setFavoriteMarkers: (markers: FavoriteMarker[]) =>
    set((state) => ({ favoriteMarkers: markers })),
  setSavedStreams: (streams: Stream[]) =>
    set((state) => ({ savedStreams: streams })),
  addMarker: (marker: FavoriteMarker) =>
    set((state) => ({
      favoriteMarkers: [...(state?.favoriteMarkers || []), marker],
    })),
  setRecentColors: (colors: string[]) =>
    set((state) => ({ userPreferences: { recentColors: colors } })),
}));

interface AppState {
  user: UserState | null;
  map: MapState | null;
  showNavigation: boolean;
  showConversations: boolean;
  setUser: (user: UserState) => void;
  setMap: (map: MapState) => void;
  toggleNavigation: (show: boolean) => void;
  toggleConversations: (show: boolean) => void;
}

export const AppState = create<AppState>((set) => ({
  user: null,
  map: null,
  showNavigation: false,
  showConversations: false,
  setUser: (user: UserState) => set((state) => ({ user: user })),
  setMap: (map: MapState) => set((state) => ({ map: map })),
  toggleNavigation: (show: boolean) => set((state) => ({ showNavigation: show })),
  toggleConversations: (show: boolean) =>
    set((state) => ({ showConversations: show})),
}));
