import { Navigate, useLocation } from "react-router-dom";
import { useContext } from 'react';
import { Context } from "../../context/AuthContext"

const ProtectedRoute = (props: any) => {
    // Grab the global user context
    const user = useContext(Context);
    const location = useLocation();

    if (!user?.user) {
        const loginUrl = `/login${location.search}`;
        return <Navigate to={loginUrl} replace state={{ from: { pathname: location.pathname, search: location.search } }} />;
    } else {
        return (
            props?.children
        )
    }
};


export default ProtectedRoute;
